import React from "react"
import Layout from "components/layout"
import Footer from "components/footer"
import SEO from "components/seo"
import { graphql, navigate } from "gatsby"
import BlogBase from "components/blog_base"
export const query = graphql`
  query {
    allFile(
      filter: { sourceInstanceName: { eq: "content" }, name: { eq: "contact" } }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              lhp
              lhs
              intro
            }
          }
        }
      }
    }
  }
`
class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      item: {},
    }
  }

  componentDidMount() {
    if (typeof window === "undefined") {
      return
    }
    this.addProps()
  }

  addProps() {
    if (this.props.location.state != null) {
      this.setState({ item: this.props.location.state.item }, () => {
        this.setState(() => ({ show: true }))
      })
      localStorage.setItem(
        "item",
        JSON.stringify(this.props.location.state.item)
      )
    } else if (this.props.location.state == null) {
      let item = JSON.parse(localStorage.getItem("item"))

      if (item == null) {
        navigate("/works/")
      } else {
        this.setState({ item: item }, () => {
          this.setState(() => ({ show: true }))
        })
      }
    }
  }

  render() {
    return this.state.show === true ? (
      <div className="body">
        <Layout link="works">
          <SEO
            title={this.state.item.title}
            description={
              this.state.item.title + " built using " + this.state.item.tech
            }
            keywords={this.state.item.tech}
          />
          <BlogBase data={this.state.item} provider="works" />
        </Layout>
        <Footer />
      </div>
    ) : null
  }
}

export default Contact
